.gx-popover-lang-scroll {
    height: 30px !important;
}

.gx-app-logo-content-bg:before {
    background-color: unset;
}

.ant-btn-primary {
    color: #fff;
    border-color: #ba9350;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
    color: #ffffff !important;
    background-color: rgb(87, 71, 156) !important;
    border-color: rgb(87, 71, 156) !important;
}

a, .gx-link {
    color: #57479c;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
    color: #ffffff !important;
    background-color: rgb(115 99 187) !important;
    border-color: rgb(115 99 187) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #57479c;
    border-color: #57479c;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
    border-color: #57479c;
}

.ant-checkbox-checked::after {
    border: 1px solid #86837c;
}

.gx-layout-sider-dark {
    background-color: #322B61;
    color: #9894AF;
}

.ant-layout-sider-dark .gx-app-nav, .gx-drawer-sidebar-dark .gx-app-nav {
    color: #9894AF;
}

.ant-layout-sider-dark .gx-sidebar-notifications, .gx-drawer-sidebar-dark .gx-sidebar-notifications {
    border-bottom-color: #9894AF;
}

.ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub {
    color: #9894AF;
    background: #322B61;
}

.ant-menu-dark .ant-menu-item > a {
    color: #9894AF;
}

.ant-menu-dark .ant-menu-item:hover, .ant-menu-dark .ant-menu-item-active, .ant-menu-dark .ant-menu-submenu-active, .ant-menu-dark .ant-menu-submenu-selected, .ant-menu-dark .ant-menu-submenu-title:hover, .ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item > a:hover {
    color: #fff;
}

.gx-layout-sider-dark .gx-icon-btn:focus, .gx-layout-sider-dark .gx-icon-btn:hover {
    background-color: #444342;
}

a:hover, .gx-link:hover, a:focus, .gx-link:focus {
    color: #8f7ed7;
}

.ant-pagination-item-active a {
    color: #57479c;
}

.ant-pagination-item-active {
    font-weight: 500;
    background: #fff;
    border-color: #57479c;
}

.ant-pagination-item:focus, .ant-pagination-item:hover {
    border-color: #57479c;
    transition: all 0.3s;
}

.ant-pagination-item:focus a, .ant-pagination-item:hover a {
    color: #57479c;
}

.ant-pagination-prev:focus .ant-pagination-item-link, .ant-pagination-next:focus .ant-pagination-item-link, .ant-pagination-prev:hover .ant-pagination-item-link, .ant-pagination-next:hover .ant-pagination-item-link {
    color: #57479c;
    border-color: #57479c;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon, .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: #57479c;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
    border-color: #57479c;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: #57479c;
    box-shadow: 0 0 0 2px rgb(87 71 156 / 20%);
}

.gx-text-orange {
    color: #f9c03f !important;
}

.gx-text-gold {
    color:gold !important;
}

.gx-text-montebravo-color-1 {
    color:#ba9350 !important;
}

.gx-text-montebravo-color-2 {
    color:#cfb085 !important;
}

.gx-text-montebravo-color-3 {
    color:#ffe2b3 !important;
}

.gx-text-montebravo-color-4 {
    color:#fac76d !important;
}

.gx-text-montebravo-color-5 {
    color:#f9c03f !important;
}

.gx-text-montebravo-dark-2 {
    color: #1f1f1f !important;
}

.gx-text-montebravo-dark-3 {
    color:#34332F !important;
}

.gx-text-montebravo-dark-4 {
    color:#4e4b44 !important;
}

.gx-text-montebravo-dark-5 {
    color:#67635b !important;
}

.recharts-tooltip-item-list li {
    color:#4e4b44 !important;
}

.recharts-tooltip-item-list li {
    color:#4e4b44 !important;
}

.gx-left-line {
    border-left: 1px solid #E8E8E8;
}

.montebravo-growth-card .recharts-tooltip-item-list li:nth-child(1) {
    color:#FE9E15 !important;
}

.montebravo-growth-card .recharts-tooltip-item-list li:nth-child(2) {
    color:#f9c03f !important;
}

.montebravo-growth-card .gx-chart-up {
    color: gold;
}

.montebravo-growth-card .gx-chart-down {
    color: #67635b;
}

.gx-bg-montebravo-color-1 {
    background-color: #ba9350;
}

.gx-bg-montebravo-color-2 {
    background-color: #cfb085;
}

.gx-bg-montebravo-color-3 {
    background-color: #ffe2b3;
}
.gx-profile-pic-upload-btn {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    text-align: center;
    padding-top: 45%;
    color: #4e4b44;
    font-size: 20px;
    opacity: 0;
    -webkit-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    cursor: pointer;
}
.gx-profile-pic-upload-btn:hover {
    opacity: 1;
}

.gx-profile-banner-avatar {
    position: relative;
}

.gx-bg-montebravo-color-4 {
    background-color: #fac76d;
}

.gx-bg-montebravo-color-5 {
    background-color: #f9c03f;
}

.gx-bg-montebravo-dark-2 {
    background-color: #1f1f1f;
}

.gx-bg-montebravo-dark-3 {
    background-color: #34332F;
}

.gx-bg-montebravo-dark-4 {
    background-color: #4e4b44;
}

.gx-bg-montebravo-dark-5 {
    background-color: #67635b;
}

.ant-btn-primary:not([disabled]):not(.disabled):active, .ant-btn-primary:not([disabled]):not(.disabled).active {
    background-color: #57479c !important;
    border-color: #57479c !important;
}
.ant-picker-focused {
    border-color: #ba9350;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(175 126 15 / 20%);
}
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    color: #fff;
    background: #ba9350;
}
.gx-profile-banner {
    padding-bottom: 50px;
}
.gx-drawer-sidebar-dark .ant-drawer-content {
    background-color: #444342;
    color: #AAA59A;
}
.gx-bottom-none {
    border-bottom: none !important;
}
.gx-month-select {
    width: 120px;
}
.gx-year-select {
    width: 90px;
}
.gx-datepicker-sm {
    height: 24px;
}
.user-group-left {

}
.user-group-right {
    margin-left: -30px;
}
.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
    border-color: #57479c;
}
.ant-input:focus, .ant-input-focused {
    border-color: #57479c;
    border-right-width: 1px !important;
    outline: 0;
    box-shadow: 0 0 0 2px rgb(87 71 156 / 31%);
}
.ant-btn:hover, .ant-btn:focus {
    color: #57479c;
    background: #fff;
    border-color: #57479c;
}
.ant-input-search .ant-input:hover + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary), .ant-input-search .ant-input:focus + .ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
    border-left-color: #57479c;
}
.btn-success {
    background-color: #8ACA2B;
    border-color: #8ACA2B;
    color: #fff;
}
.btn-success:hover, .btn-success:active, .btn-success:focus {
    background-color: #aee856;
    border-color: #aee856;
    color: #fff;
}
.btn-blue {
    background-color: #0D8AE6;
    border-color: #0D8AE6;
    color: #fff;
}
.btn-blue:hover, .btn-blue:active, .btn-blue:focus {
    background-color: #53a4e0;
    border-color: #53a4e0;
    color: #fff;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    color: #242424;
    font-weight: 400;
    line-height: 1.2;
}

.text-gray, .text-gray:active, .text-gray:focus {
    color: #898a98 !important;
}

.ant-input-affix-wrapper:hover {
    border-color: #8f7ed7;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
    border-color: #8f7ed7;
    box-shadow: 0 0 0 2px rgb(143 126 215 / 20%);
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgb(173 160 225 / 40%);
}
.ant-popover-inner-content {
    padding: 12px 16px;
    color: #898a98;
}
.ant-popover-inner-content a {
    color: #898a98 !important;
}
.ant-input:hover {
    border-color: #57479c;
}
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::before,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: #9894AF;
}
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active > .ant-menu-submenu-title:hover > .ant-menu-submenu-arrow:after {
    background: #fff;
}
.ant-menu-dark .ant-menu-submenu-title:hover {
    color: #fff;
}
.ant-layout-sider .ant-menu-submenu > .ant-menu.ant-menu-sub .ant-menu-item {
    padding-left: 50px !important;
}

.gx-profile-banner {
    background-color: #57479c;
}
.ant-modal-confirm-btns .ant-btn-dangerous {
    color: #f5222d !important;
    background: #fff !important;
    border-color: #f5222d !important;
}

.ant-popover-disabled-compatible-wrapper {
    margin-left: 15px;
}