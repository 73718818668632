/*Loader Styles*/
.ant-spin {
  margin-right: 16px;
  margin-bottom: 10px;
}

.@{class-prefix}-loader-container {
  text-align: center;
  background-color: @grey-3;
  .border-radius(@border-radius-sm);
  padding: @size-30 @size-50;
  margin-right: 0;
  display: block;
}
